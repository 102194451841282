import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { DocumentoModel } from '../models/documento.model';

@Injectable({
  providedIn: 'root'
})
export class CargaArchivosService extends MainService {

  constructor(public http: HttpClient) {
    super(http);
  }

  agregar(objeto: any) {
    return this.postAsync(this.gatewayArchivos + 'AdminArchivos/adArchivos/Agregar/29493240-94E9-4531-8DED-797C95233C35', objeto);
  }

  obtenerArchivo(token: string) {
    return this.gatewayArchivos + 'AdminArchivos/adArchivos/Visor/29493240-94E9-4531-8DED-797C95233C35/' + token;
  }

}
