import { PdfService } from 'src/app/services/pdf.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-consulta-rud',
  templateUrl: './consulta-rud.component.html',
  styleUrls: ['./consulta-rud.component.scss']
})
export class ConsultaRudComponent implements OnInit {
  pdfSrc:string ='';
  @Input() urlPdf: string;
  constructor(private pdfService:PdfService,) { }

  ngOnInit(): void {
    //alert(this.urlPdf);
    this.pdfSrc = this.pdfService.getPDF();
  }

}
