<!--Start Back To Top Button-->
<a href="javaScript:;" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a>
<!--End Back To Top Button-->

<!--Start footer-->
<footer class="footer">
    <div class="container">
        <div class="text-center">
            Copyright © 2022 RENADE
        </div>
    </div>
</footer>
<!--End footer-->