import { Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'components',
        loadChildren: () => import('../../components/components.module').then(m => m.ComponentsModule),
        canActivate: [AuthGuard]
    },
];
