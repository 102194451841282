import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService} from './main.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService  extends MainService {

  constructor(public http: HttpClient) {
    super(http);
  }

}
