import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/components/home', title: 'Home', icon: 'fas fa-home icon-cog1', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '', title: 'Catálogos', icon: 'fa fa-list-ul', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [

        {
          path: '/components/catalogosDeportes', title: 'Deportes', icon: 'fa fa-street-view icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
        {
          path: '/components/catalogosArchivosOrganismos', title: 'Archivos Organismos', icon: 'fa fa-file icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
        },
      ]
    },
    /*{
      path: '/components/personas-morales', title: 'P. Morales', icon: 'fas fa-users icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },*/
    {
      path: '/components/personas-fisicas', title: 'P. Fisicas', icon: 'fas fa-user icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '/components/organismos', title: 'Organismos', icon: 'fas fa-user icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '/components/solicitud-personas-fisicas', title: 'Solicitud P. Fisicas', icon: 'fas fa-envelope icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '/components/infraestrutura-deportiva', title: 'Infraestructura Deportiva', icon: 'fa-solid fa-building icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    //fas fa-envelope icon-cog

    // {
    //   path: '/components/nueva-solicitud', title: 'Nueva solicitud', icon: 'fas fa-envelope icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },


];
