<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<main class="">  
  <header class="mb-3">
    <img src="assets/images/conade.png" alt="loading-icon" />
    <img src="assets/images/LOGO_SINADE_HORIZONTAL.png" alt="loading-icon" />
    <img src="assets/images/Logo-RENADE.png" alt="loading-icon" />
  </header>
  <div class="card mb-5">
    <div class="card-header">
       <p>HOME</p> 
       <p>Introduce tu Clave Alfanumérica, CURP, RFC o Razón Social.</p>
    </div>
    <form action="mb-5">
      <div class="card-body mb-5">
        <div class="row mt-1">
          <div class="col-12 inputsCustom">
            <!--pantalla > 992px de ancho -->
            <div class="d-none d-sm-none d-md-none d-lg-block ">
              <div class="input-group mb-2 col-md-12 ">
                <select class="custom-select selectCategoria" name="selectCategoria" [(ngModel)]="selectCategoria">
                  <option value="0">Seleccione una opción</option>
                  <option *ngFor="let item of catalogosItem" value="{{item.id}}">{{item.descripcion}}</option>
                </select>
                <div class="input-group-prepend">
                  <input type="text" class="form-control cajaDeTexto" placeholder="..." aria-label="cajaDeTexto"
                    aria-describedby="cajaDeTexto" name="cajaDeTexto" [(ngModel)]="cajaDeTexto" [disabled]="(selectCategoria < 1)?true:false">
                </div>
              </div> 
              <div class="infoTexto mb-2" *ngIf="cajaDeTexto ===''">Ingrese los datos.</div>
            </div>
            <!--pantalla < 992px de ancho--> 
            <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
              <div class="mb-2">
                <label for="select" class="form-label">Ingrese una categoria:</label>
                <select style="width: 100%;" class="custom-select selectCategoria" name="selectCategoria" [(ngModel)]="selectCategoria" >
                  <option value="0">Seleccione una opcion</option>
                  <option *ngFor="let item of catalogosItem" value={{item.id}}>{{item.descripcion}}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Ingrese texto:</label>
                <input type="text" class="form-control cajaDeTexto" placeholder="..." aria-label="cajaDeTexto"
                  aria-describedby="cajaDeTexto" name="cajaDeTexto" [(ngModel)]="cajaDeTexto" [disabled]="(selectCategoria < 1)?true:false">
                  <small id="cajaDeTexto1" class="form-text text-muted" *ngIf="cajaDeTexto ===''">Ingrese los datos.</small>
              </div>
            </div>           
          </div>
        </div>
        <div class="catcha">
          <re-captcha class="mb-2" id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
        </div>
        <div class="boton">
          <button type="button" class="btn btn-outline-success" disabled="disabled"      *ngIf="selectCategoria < 1 || cajaDeTexto ==='' ||  token == null">Buscar</button>
          <button type="button" class="btn btn-outline-success" (click)="validarBoton()" *ngIf="selectCategoria > 0 && cajaDeTexto !=='' && token != null">Buscar</button>
        </div>        
        <ul class="list-group d-block d-sm-block d-md-block d-lg-none mt-2" *ngIf="validado !==false">
          <li class="list-group-item"><b>Razon Social:</b> {{retornoTabla.razonSocial}} </li>
          <li class="list-group-item"><b>Direccion:</b> {{retornoTabla.direccion}}</li>
          <li class="list-group-item"><b>RFC o CURP:</b>{{retornoTabla.rfc}} {{retornoTabla.curp}}</li>
          <li class="list-group-item"><b>Tipo de Organismo:</b> {{retornoTabla.tipoOrganismo}}</li>
          <li class="list-group-item"><b>Fecha Alfiliacion:</b> {{retornoTabla.fechaAfiliacion}}</li>
        </ul>
        <div class="table-responsive d-none d-sm-none d-md-none d-lg-block d-xl-block">
          <table *ngIf="validado !==false" class="table table-striped table-bordered border-dark mt-2 ">
            <thead>
              <tr class="colorTH">
                <th scope="col">Razon Social</th>
                <th scope="col">Direccion</th>
                <th scope="col">RFC o CURP</th>
                <th scope="col">Tipo de Organismo</th>
                <th scope="col">Fecha Alfiliacion</th>
              </tr>
            </thead>
            <tbody >
              <tr class="table-light">
                <th>{{retornoTabla.razonSocial}}</th>
                <td>{{retornoTabla.direccion}}</td>
                <td>{{retornoTabla.curp}}{{retornoTabla.rfc}}</td>
                <td>{{retornoTabla.tipoOrganismo}}</td>
                <td>{{retornoTabla.fechaAfiliacion}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
    
  </div>
  <footer>
    copyright ©2022 RENADE
  </footer>
</main>

