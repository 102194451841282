<div id="wrapper" [ngClass]="{'nav-collapsed' : getSideBarState()}">
    <app-sidebar></app-sidebar>
    <app-navbar></app-navbar>
    <div class="clearfix"></div>
    <div class="content-wrapper">
        <div class="container-fluid">
            <router-outlet></router-outlet>
            <div class="overlay" (click)="toggleSidebar()" [ngClass]="{'show' : getSideBarState()}"></div>
        </div>
    </div>
    <app-footer></app-footer>
    <!-- <app-color-switcher></app-color-switcher> -->
</div>

<!--Modal with Default options starts-->
<ng-template #modalChangePass let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Cambiar Contraseña</h4>
        <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">

        <form id="formChangePass" name="formPass" #form="ngForm">
            <div class="form-group" *ngIf="deshabilitado">
                <label for="form-rpass">Contraseña Actual</label>
                <input type="password" class="form-control" id="form-apass" [(ngModel)]="actContrasena" (keyup.enter)="comparaContrasena()" name="apass" #inputAPass="ngModel" required>
                <small class="form-text text-muted text-danger" *ngIf="notEquals">
                  La contraseña no coincide con la actual
                </small>
            </div>
            <div class="form-group" *ngIf="!deshabilitado">
                <label for="form-pass">Nueva Contraseña</label>
                <p-password id="form-pass" [(ngModel)]="contrasena" (ngModelChange)="comparaPass()" name="pass" [toggleMask]="true" #inputPass="ngModel" required [style]="{'width' : '100%'}" [weakLabel]="debil" [mediumLabel]="media" [strongLabel]="alta"></p-password>
                <small class="form-text text-muted text-danger" *ngIf="!inputPass.valid && (inputPass.dirty || inputPass.touched)">
              El campo es requerido
            </small>
            </div>
            <div class="form-group" *ngIf="!deshabilitado">
                <label for="form-rpass">Repite la Contraseña</label>
                <input type="password" class="form-control" id="form-rpass" autocomplete="false" [(ngModel)]="repeatContrasena" (ngModelChange)="comparaPass()" name="rpass" #inputRPass="ngModel" required>
                <small class="form-text text-muted text-danger" *ngIf="!inputRPass.valid && (inputRPass.dirty || inputRPass.touched)">
                  El campo es requerido
                </small>
                <small class="form-text text-muted text-success" *ngIf="equalsPass">
                  Las contraseñas coinciden
                </small>
                <small class="form-text text-muted text-danger" *ngIf="longPass">
                  La contraseña debe tener una longitud minima de 5 caracteres
                </small>
                <small class="form-text text-muted text-danger" *ngIf="repeatContrasena.length > 0 && !equalsPass">
                  Las contraseñas no coinciden
                </small>
            </div>
            <button type="submit" class="btn btn-success mt-2" (click)="guardarCambios()" [disabled]="!equalsPass" style="width: 100%;" *ngIf="!deshabilitado">Guardar</button>
        </form>

    </div>
</ng-template>
<!--Modal with Default options ends-->