

<div class="container">
<div class="card">
  <div class="d-flex justify-content-end">
    <i class="fa fa-eye p-3 text-dark" (click)="openModal()" ></i>
    <i class="fa fa-external-link-alt p-3 text-dark"></i>
    <a href="../../../assets/manual.pdf" download><i class="fa fa-download p-3 text-dark"></i></a>
  </div>
  <div class="card-body">
    <pdf-viewer [src]="pdfSrc"
    [render-text]="true"

    [show-all]="true"
    style="display: block;">
    </pdf-viewer>
  </div>
</div>
</div>
<div>
  <!-- <label for="">Pdf source</label> -->
  <!-- <input (change)="onFileSelected()" type="file" id="file">
  <input [(ngModel)]="page" type="text" id="page"> -->


</div>

<ng-template #modalConsultaRud let-modal>

  <div class="modal-header">
    <div>
      <h4>Consulta RUD</h4>
    </div>
      <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="modal-body">
    <app-consulta-rud (click)="getPdf()"></app-consulta-rud>
      <!-- <app-add-categoria [_categoriaEditar]="categoriaEdit" (response)="respuestaAdd($event)"></app-add-categoria> -->
  </div>
</ng-template>
