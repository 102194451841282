<!--start color switcher-->
<div class="right-sidebar">
  <div class="switcher-icon">
    <i class="zmdi zmdi-settings zmdi-hc-spin"></i>
  </div>
  <div class="right-sidebar-content">


 <p class="mb-0">Header Colors</p>
    <hr>
  
  <div class="mb-3">
    <button type="button" id="default-header" class="btn btn-sm btn-outline-primary">Default Header</button>
  </div>
    
    <ul class="switcher">
      <li id="header1" ></li>
      <li id="header2"></li>
      <li id="header3"></li>
      <li id="header4"></li>
      <li id="header5"></li>
      <li id="header6"></li>
      <li id="header7"></li>
      <li id="header8"></li>
      <li id="header9"></li>
      <li id="header10"></li>
      <li id="header11"></li>
    </ul>

    <p class="mb-0">Sidebar Colors</p>
    <hr>
  
    <div class="mb-3">
    <button type="button" id="default-sidebar" class="btn btn-sm btn-outline-primary">Default Sidebar</button>
  </div>
  
    <ul class="switcher">
      <li id="theme1"></li>
      <li id="theme2"></li>
      <li id="theme3"></li>
      <li id="theme4"></li>
      <li id="theme5"></li>
      <li id="theme6"></li>
      <li id="theme7"></li>
      <li id="theme8"></li>
      <li id="theme9"></li>
      <li id="theme10"></li>
      <li id="theme11"></li>
    </ul>


    <p class="mb-0">Sidebar Active Colors</p>
    <hr>
  
    <div class="mb-3">
    <button type="button" id="default-sidebar-active" class="btn btn-sm btn-outline-primary">Default Sidebar Active</button>
  </div>
  
    <ul class="switcher">
      <li id="active1"></li>
      <li id="active2"></li>
      <li id="active3"></li>
      <li id="active4"></li>
      <li id="active5"></li>
      <li id="active6"></li>
    </ul>

    
    <p class="mb-0">Sidebar Shadow Colors</p>
    <hr>
  
    <div class="mb-3">
    <button type="button" id="default-shadow" class="btn btn-sm btn-outline-primary">Default Shadow</button>
  </div>
  
    <ul class="switcher">
      <li id="shadow1"></li>
      <li id="shadow2"></li>
      <li id="shadow3"></li>
      <li id="shadow4"></li>
      <li id="shadow5"></li>
      <li id="shadow6"></li>
    </ul>
    
   </div>
 </div>
<!--end color switcher-->