import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { RudComponent } from './components/rud/rud.component';
import { ActualizarRUDComponent } from './components/actualizar-rud/actualizar-rud.component';
import { ValidacionClaveAlfanumericaComponent } from './validacion-clave-alfanumerica/validacion-clave-alfanumerica.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/signin',
    pathMatch: 'full',
  },

  {path: 'RUD', component: RudComponent, data: {title: 'RUD'}},
  {path: 'validacion-clave-Alfanumerica', component: ValidacionClaveAlfanumericaComponent},
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  { path: '**', redirectTo: '/auth/signin' },
  
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

