import { Injectable } from '@angular/core';
import swal from "sweetalert2";
@Injectable({
  providedIn: 'root'
})
export class SwalServices {
    public casoAlerta=["Guardado","guardar","Editado","editar","Eliminado","eliminar","Ingrese los datos correctamente","ingrese recatcha"];
    public type=["success","error","warning","info","question"];
    public title=["Exito!","Error!","¿Desea eliminar este elemento?", "Sin registros!"];
    public textExito=[
        this.casoAlerta[0]+" correctamente.",
        this.casoAlerta[2]+" correctamente.",
        this.casoAlerta[4]+" correctamente."
    ];
    public textError=[
        "Ocurrio un error al "+this.casoAlerta[1]+".",
        "Ocurrio un error al "+this.casoAlerta[3]+".",
        "Ocurrio un error al "+this.casoAlerta[5]+".",
    ];
    constructor() {}
    public alertaPersonalizado(alert:boolean,mensaje:string){
        this.swalEjecucuin(
            ((alert===true)?this.title[0]:this.title[1]),
            mensaje,
            ((alert===true)?this.type[0]:this.type[1])
        );
    }
    public alertaPersonalizadoInfo(mensaje:string){
        this.swalEjecucuin(
            this.title[1],
            this.casoAlerta[6],
            this.type[3]
        );
    }
    public alertaAuditoria(){
        this.swalEjecucuin(
            this.title[3],
            '',
            this.type[3]
        );
    }
    public datosnoEncontrados(){
        this.swalEjecucuin(
            this.title[1],
            "Datos no encontrados",
            this.type[3]
        );
    }
    public alertaRecatchaInfo(){
        this.swalEjecucuin(
            this.title[1],
            this.casoAlerta[7],
            this.type[3]
        );
    }
    public errorEnLosDatos(alert:boolean,mensaje:string){
        this.swalEjecucuin(
            ((alert===true)?this.title[6]:this.title[1]),
            mensaje,
            ((alert===true)?this.type[3]:this.type[1])
        );
    }
    public agregarPersona(alert:boolean, longitud: number, mensaje: string){
        if(longitud == 132){
            this.swalEjecucuin(
                ((alert===true)?this.title[0]:this.title[1]),
                ((alert===true)?this.textExito[0]:mensaje.substring(39,132)),
                ((alert===true)?this.type[0]:this.type[1])
                );
        }
        else{
            this.swalEjecucuin(
                ((alert===true)?this.title[0]:this.title[1]),
                ((alert===true)?this.textExito[0]:this.textError[0]),
                ((alert===true)?this.type[0]:this.type[1])
                );
        }
    }
    public agregarOrganismo(alert:boolean, longitud: number, mensaje: string){
        if(longitud == 120){
            this.swalEjecucuin(
                ((alert===true)?this.title[0]:this.title[1]),
                ((alert===true)?this.textExito[0]:mensaje.substring(39,120)),
                ((alert===true)?this.type[0]:this.type[1])
                );
        }
        else{
            this.swalEjecucuin(
                ((alert===true)?this.title[0]:this.title[1]),
                ((alert===true)?this.textExito[0]:this.textError[0]),
                ((alert===true)?this.type[0]:this.type[1])
                );
        }
    }
    public agregarElemento(alert:boolean){
        this.swalEjecucuin(
            ((alert===true)?this.title[0]:this.title[1]),
            ((alert===true)?this.textExito[0]:this.textError[0]),
            ((alert===true)?this.type[0]:this.type[1])
            );
    }
    public editarElemento(alert:boolean){
        this.swalEjecucuin(
            ((alert===true)?this.title[0]:this.title[1]),
            ((alert===true)?this.textExito[1]:this.textError[1]),
            ((alert===true)?this.type[0]:this.type[1])
            );
    }
    public eliminarElemento(alert:boolean){
        this.swalEjecucuin(
            ((alert===true)?this.title[0]:this.title[1]),
            ((alert===true)?this.textExito[2]:this.textError[2]),
            ((alert===true)?this.type[0]:this.type[1])
            );
    }
    public swalEjecucuin(title,text,type){
        swal({
            title: title,
            text: text,
            //timer: 2000,
            type: type
        });
    }
    public cargaDeDatos(){
        swal({
            title: `Buscando datos`,
            text: "por favor espere...",
            type: 'info',
            allowOutsideClick: false
        });
        swal.showLoading();/** Para que aparezca el simbolo de cargando y el usuario no pueda hacer nada */
    }
    public cerrarAlertaActiva(){
        swal.close();
    }
}
