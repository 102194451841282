import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VariablesEnum } from '../auth/enum/variables.enum';
import { datosUsuarioLogeado } from '../models/usuario.model';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
  }

  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


//CODIGO PARA REDIRIGIR A HTTPS
// if(environment.production){
//   if(location.protocol === 'http:' ){
//     window.location.href = location.href.replace('http','https');
//   }
// }

//debugger

    const session = this.localStorageService.getJsonValue(VariablesEnum.usuario_conade) as string;
      let sesionSerializada = JSON.parse(session);
    if (route.routeConfig.path === 'auth') {

      if (session ) {

        this.router.navigate(['components/home']);

        return false;
      }
      return true;

    }else {

      //SE COMPARA EL TIEMPO DE EXPIRACION CON EL ACTUAL, SI ÉSTE ÚLTIMO ES MAYOR, ENTONCES SE MANDA EL CIERRE DE SESIÓN

      let fechaExpiracion = new Date(sesionSerializada.expireTimeDate);
      if (session && sesionSerializada.expireTime > new Date().getTime()) {

        return true;

      } else {
        this.localStorageService.clearToken();
        this.router.navigate(['auth/signin']);

        return false;
      }
    }
  }

}
