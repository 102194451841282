import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';
import { claveAlfaNumericaModel } from '../models/claveAlfaNumerica.model';
import { RespuestaModel } from '../models/respuesta.model';
@Injectable({
providedIn: 'root'
})
export class ClaveAlfaNumericaService extends MainService {
  constructor(public http: HttpClient)
  {
    super(http);
  }
  /** CLAVE ALFANUMERICA*/
  buscarPorCategorias(objeto: any): Promise <RespuestaModel> {

    //console.log("token:", localStorage.getItem('token'));
    return this.postAsync(this.gatewayDesarrollo + 'BusquedaRENADE/Consulta', objeto);
  }
  //BusquedaRENADE/Catalogo
  public async Categorias()  : Promise<any>
  {

      //console.log("token:", localStorage.getItem('token'));
      return await this.getAsync(this.gatewayDesarrollo + 'BusquedaRENADE/Catalogo')
  }
  public async obtenerToken(): Promise <any> {
    return this.postAsync(this.gatewayDesarrollo + 'Authentication', {});
  }
}
