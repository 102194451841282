<!--Start topbar header-->
<header class="topbar-nav">
    <nav id="header-setting" class="navbar navbar-expand fixed-top bg-white">
        <ul class="navbar-nav mr-auto align-items-center">
            <li class="nav-item">
                <a class="nav-link toggle-menu" (click)="toggleSidebar()">
                    <i class="icon-menu menu-icon"></i>
                </a>
            </li>
        </ul>
        <!-- notificaciones inicio-->
        <ul class="navbar-nav align-items-center right-nav-link">
            <li class="nav-item border-left" ngbDropdown placement="bottom-right">
                <!--campana-->
                <a href="javascript:;" class="nav-link dropdown-toggle dropdown-toggle-nocaret" id="prueba1" ngbDropdownToggle>
                    <i class="fas fa-bell mr-3"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="prueba1" class="dropdown-menu menu-notificaciones dropdown-menu-right animate__animated animate__fadeInUp animate__faster">
                    <div class="tituloConfig">
                        <h2>NOTIFICACIONES</h2>
                        <i class="fas fa-cog"></i>
                        <div ngbDropdownMenu aria-labelledby="prueba2" class="dropdown-menu menu-notificaciones dropdown-menu-right animate__animated animate__fadeInUp animate__faster"></div>
                    </div>
                    <div class="botones">
                        <button type="button" class="btn btn-primary">En espera</button>
                        <button type="button" class="btn btn-primary">Mas recientes</button>
                        <button type="button" class="btn btn-primary">Anteriores</button>
                        <button type="button" class="btn btn-primary">Todas</button>
                        <!-- <button type="button" class="btn btn-secondary">No leidas</button> -->
                    </div>
                    <div class="nuevas">
                        <div class="titulo">
                            <h5>Nuevas</h5> <a href="">Ver todo</a>
                        </div>
                        <div class="contenedorNotificaciones">
                            <div class="row" *ngFor="let resp of modelResp">
                                <h6>{{resp.idModificacion}}</h6>
                                <p>{{resp.idPersona}}</p>
                                <h6>{{resp.fechaRegistro | date: 'dd-MM-yyyy'}}</h6>
                                <p>{{resp.comentarios}}</p>
                                <i class="fas fa-ellipsis-h"></i>
                            </div>
                        </div>
                    </div>
                    <div class="anteriores">

                    </div>
                    <div class="verTodas">
                        <button type="button" class="btn ">Ver Todas</button>
                    </div>
                </div>
            </li>
        </ul>
        <!-- notificaciones fin-->
        <ul class="navbar-nav align-items-center right-nav-link">
            <li class="nav-item border-left" ngbDropdown placement="bottom-right">
                <a href="javascript:;" class="nav-link dropdown-toggle dropdown-toggle-nocaret" id="prueba" ngbDropdownToggle>
                    <i class="fas fa-user-cog"></i>
                    <p class="ml-3 mb-0 float-right extra-small-font hidden-xs">{{ nombreCompleto }} </p>
                </a>
                <ul ngbDropdownMenu aria-labelledby="prueba" class="dropdown-menu dropdown-menu-right animate__animated animate__fadeInUp animate__faster">
                    <li class="dropdown-item user-details">
                        <a href="javaScript:;">
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-2 user-title">{{ nombreCompleto }}</h6>
                                    <p class="user-subtitle">{{ authUs.usCorreo }}</p>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li class="dropdown-item cp" (click)="openModal()"><i class="fas fa-cogs cp"></i> Cambiar Contraseña</li>
                    <!-- <li class="dropdown-divider"></li> -->
                    <li class="dropdown-item cp" (click)="Logout()"><i class="fas fa-power-off cp"></i> Logout</li>
                </ul>
            </li>
        </ul>
    </nav>
</header>
<!--End topbar header-->