import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  stateSideBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
    this.stateSideBar.next(this.toggled);
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
    this.stateSideBar.next(this.toggled);
  }
}
