import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalServices } from '../services/alertas-swit.service';
import { ConsultaClaveAlfanumerica ,retornoValidacionAlfanumerica,catalogos} from '../models/validacion-clave-alfanumerica.model';
import { ClaveAlfaNumericaService } from '../services/clave-alfa-numerica.service';
import { VariablesService } from '../services/variableGL.service';
@Component({
  selector: 'app-validacion-clave-alfanumerica',
  templateUrl: './validacion-clave-alfanumerica.component.html',
  styleUrls: ['./validacion-clave-alfanumerica.component.scss']
})
//https://dev.to/rodrigokamada/adding-the-google-recaptcha-v2-to-an-angular-application-1o7o
export class ValidacionClaveAlfanumericaComponent implements OnInit {
  public selectCategoria:number=0;
  public cajaDeTexto:string="";
  public validado:boolean=false;
  public consultaClaveAlfanumerica:ConsultaClaveAlfanumerica;
  public catalogosItem:catalogos[];
  //public consultaClaveAlfanumerica;
  public datosTabla={razonSocial:"pruebaRS",direccion:"pruebaDireccion",RFCoCURP:"pruebaRFCoCURP",TipoOrganismo:"pruebaOrganismo",fecha:"10-02-2022"};
  public retornoTabla:retornoValidacionAlfanumerica;
  //recatcha
  public token: string|undefined;
  constructor(private alertasSw:SwalServices,private claveAlfanumerica:ClaveAlfaNumericaService, private tostServise:VariablesService) {this.token = undefined}
  //@ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  async ngOnInit()
  {
    await this.obtenerToken();
    await this.catalogos();
  }
  public async obtenerToken(){
    let res= await this.claveAlfanumerica.obtenerToken();
    //console.log(res);
    console.log("token: ",res.token);
    console.log("expira: ",res.expira);
    localStorage.setItem('token',res.token);

  }
 public async catalogos(){
  this.alertasSw.cargaDeDatos();
  let res = await this.claveAlfanumerica.Categorias();
  if(res.estado ===true){
    
    this.catalogosItem=res.respuesta;
    //console.log(this.catalogosItem);
  }else{
    this.tostServise.toastErr("Catalogos no encontrados en el servidor");
  }
  this.alertasSw.cerrarAlertaActiva();
 }
 public async validarBoton(){
    
    //console.log(this.token);
    //console.log("capcha verificado: "+this.token);
    //console.log(this.selectCategoria);
    if(this.token === "" || this.token === undefined){
      this.alertasSw.alertaRecatchaInfo();
    }else{
      if(this.selectCategoria <1 || this.cajaDeTexto==="" || this.token === "" || this.token === undefined){
        this.alertasSw.alertaPersonalizadoInfo("");
        this.validado=false;
        this.token="";
      }else{
        //metodo de llenado
        this.consultaClaveAlfanumerica={idTipoParametro:Number(this.selectCategoria),parametro:String(this.cajaDeTexto)};
        this.selectCategoria=0;
        this.cajaDeTexto="";
        //this.alertasSw.alertaPersonalizado(true,"falta validacion End Point");
        //console.log(this.consultaClaveAlfanumerica);
        this.token="";  
        let res = await this.claveAlfanumerica.buscarPorCategorias(this.consultaClaveAlfanumerica);
        //console.log(res);
        //this.alertasSw.cargaDeDatos();
        if(res.estado){
          //this.alertasSw.alertaPersonalizado(true,"");
          this.alertasSw.cerrarAlertaActiva();
          this.retornoTabla=res.respuesta[0];
          //console.log(this.retornoTabla);
          this.validado=true;
        }else{
          this.alertasSw.cerrarAlertaActiva();
          this.alertasSw.datosnoEncontrados();
          //this.alertasSw.alertaPersonalizado(false,"");
          this.validado=false;
          //validacion false
        }
        
      }
    }
  }
}
