import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-rud',
  templateUrl: './rud.component.html',
  styleUrls: ['./rud.component.scss']
})
export class RudComponent implements OnInit {
  pdfSrc:string ='';
  @ViewChild('modalConsultaRud', { static: false }) modalConsultaRud: TemplateRef<any>;
  modalRefConsulta: any;
  constructor(
    private pdfService:PdfService,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.pdfSrc = this.pdfService.getPDF();
  }
  onFileSelected(){
    let img:any = document.querySelector('#file');
    if(typeof(FileReader) !=='undefined'){
      let reader = new FileReader();
      reader.onload =(e:any) => {
        this.pdfSrc = e.target.result;
      }
      reader.readAsArrayBuffer(img);
    }

  }
  getPdf(){
    this.pdfSrc = this.pdfService.getPDF();
  }
  openModal() {
    this.modalRefConsulta = this.modalService.open(this.modalConsultaRud, { size: 'xl', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', scrollable: true });
  }
  cerrarModal() {
    // this.categoriaEdit = new CategoriasTKY();
    this.modalRefConsulta.close();
  }

}
