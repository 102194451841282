import swal from 'sweetalert2';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SidebarService } from '../../shared/sidebar/sidebar.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VariablesService } from '../../services/variableGL.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { VariablesEnum } from 'src/app/auth/enum/variables.enum';
import { Usuario } from '../../models/usuario.model';
import { CMSUserService } from '../../services/cms-user.service';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {
  @ViewChild('modalChangePass', { static: false }) modalChangePass: TemplateRef<any>;
  modalRefPass: any;

  actContrasena: string = "";
  contrasena: string = "";
  repeatContrasena: string = "";
  debil = "Contraseña demasiado débil";
  media = "Contraseña medianamente segura";
  alta = "Contraseña altamente segura";

  deshabilitado: boolean = true;
  notEquals: boolean = false;
  equalsPass: boolean = false;
  longPass: boolean = false;
  usuario: Usuario = new Usuario();
    constructor(
      public sidebarservice: SidebarService,
      private router: Router,
      private modalService: NgbModal,
      private variablesService: VariablesService,
      private localStorageService: LocalStorageService,
      private usuariosService: CMSUserService
    ) {
      this.usuario = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));
    }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

        this.variablesService.modalCambiaContrasena.subscribe(value => {
          if(value){
            this.initVariables();
            this.openModal();
          }else if(!value){
            if(this.modalRefPass){
              this.initVariables();
              this.modalRefPass.close();
            }
          }
        });
    }

    initVariables(){
      this.actContrasena = "";
      this.contrasena = "";
      this.repeatContrasena = "";
      this.deshabilitado = true;
      this.notEquals = false;
      this.equalsPass = false;
      this.longPass = false;
    }

    openModal() {
      this.modalRefPass = this.modalService.open(this.modalChangePass, { centered: true, scrollable: true });
    }

    cerrarModal() {
      this.variablesService.modalCambiaContrasena.next(false);
    }

    comparaPass(){
      if(this.contrasena.length > 4){
        if(this.contrasena === this.repeatContrasena){
          this.equalsPass = true;
          this.longPass = false;
        }else{
          this.equalsPass = false;
          this.longPass = false;
        }
      }else{
        this.equalsPass = false;
        this.longPass = true;
      }
    }

    comparaContrasena(){
      if(this.variablesService.encryptMD5(this.actContrasena) === this.usuario.Contrasena){
        this.deshabilitado = false;
        this.notEquals = false;
      }else{
        this.notEquals = true;
        this.deshabilitado = true;
      }
    }

    async guardarCambios(){
      swal({
        title: `¿Desea cambiar su contraseña?`,
        text: "Tendrá que volver a iniciar sesión!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText: 'Si, Cambiar',
        cancelButtonText: "No, Cancelar",
        allowOutsideClick: false
      }).then(async (isConfirm) => {
          if (isConfirm.value) {

            this.usuario.Contrasena = this.variablesService.encryptMD5(this.contrasena);

            let res = await this.usuariosService.CambiaPass(this.usuario);

            //console.log("respuesta ", res);
            if (res.EXITO){
              this.variablesService.toastSuccess(`Los datos del usuario se actualizaron correctamente`);
              this.cerrarModal();
              setTimeout(() => {
                this.localStorageService.clearToken();
                this.router.navigate(['auth/signin']);
              }, 2000);
            }else{
              this.variablesService.toastErr("Hubo un error al actualizar los datos del usuario!!");
            }

          }
      });

    }
}
