import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: [`
      img {
        background: #752A31;
        width: 3.5em !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: .5em;
        margin-top: -.3em;
      }
    `],
     styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    idLogin: string;
    constructor() {
      this.idLogin = localStorage.getItem('idLogin');
    }

    ngOnInit() 
    {
        $.getScript('./assets/js/app-sidebar.js');
        //obtiene el comportamiento de menu
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (this.idLogin == '735') {
          this.menuItems.splice(this.menuItems.findIndex(x => x.title == "Solicitud P. Fisicas"),1);
        }
        //console.log(this.menuItems);
    }

}
