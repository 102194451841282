import { HttpClient,HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaModel } from '../models/respuesta.model';

@Injectable({
  providedIn: 'root'
})
export class MainService {

//PRODUCCION!!!!!!!

  gatewaycurp            = 'https://wsrenapo.conade.gob.mx/';
  gatewayArchivos        = 'https://adminarchivos.conade.gob.mx/';
  gatewayApi             = 'https://renadeservhisto.conade.gob.mx/'; // Es el que uso para Renade Microservicios
  gatewayApiLogin        = 'https://boveda.conade.gob.mx/';
  gatewayApiDoc          = 'https://adminarchivos.conade.gob.mx/';
  gateawayPruebas        = 'https://renadeservhisto.conade.gob.mx/';
  gatewayOrganismo       = "https://renadeservhisto.conade.gob.mx/";
  gatewayPersonasFisicas = "https://renadeservhisto.conade.gob.mx/";
  gatewayDesarrollo      = "http://10.10.0.32:8120/";
  gatewayInfraestructura = "https://renadeservhisto.conade.gob.mx/";
  gatewayPDF             = "https://rudservicio.conade.gob.mx/";
  //gatewayPDF             = "https://localhost:7022/"
  


  //gateway = http://10.10.0.32:9617/  PARA ARCHIVOS Y SOLICITUD PERSONA FISICA - SERVER 32 - PORT FRONT 9718 - 9717 BACK

  //DESARROLLO
  
  //  gatewayDesarrollo      = "http://10.10.0.32:8108/";
  //  gatewaycurp            = 'https://wsrenapo.conade.gob.mx/';
  //  gatewayArchivos        = 'http://10.10.0.32:8083/';
  //  gatewayApi             = 'http://10.10.0.32:8108/';
  //  gatewayApiLogin        = 'https://boveda.conade.gob.mx/';
  //  gatewayApiDoc          = 'http://198.251.71.105:90/';
  //  gateawayPruebas        = 'http://10.10.0.32:8108/'
  //  gatewayOrganismo       = "http://10.10.0.32:8108/";
  //  gatewayPersonasFisicas = "http://10.10.0.32:8108/";
  //  gatewayInfraestructura = 'http://10.10.0.32:8108/';
  
  /*
  https://renadeservhisto.conade.gob.mx/
  https://renadeservhisto.conade.gob.mx/
  https://adminarchivos.conade.gob.mx/
  https://adminarchivos.conade.gob.mx/
  */

  //header
  public header ={
    "Authorization": `Bearer ${localStorage.getItem('token')}`,
    "idUsuario": `${localStorage.getItem('idLogin')}`,

  };
  public headerPdf ={
    "Authorization": `Bearer ${localStorage.getItem('token')}`,
    // "idUsuario": `${localStorage.getItem('idLogin')}`,
    // responseType: 'arraybuffer'

  };
  constructor(public httpClient: HttpClient) { }

  getAsync(url: string): Promise<any> {

    return new Promise(resolve => {
      const subscription = this.httpClient.get<any>(url,{headers: this.header})
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);

          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  getAsyncCurpMesa(url: string): Promise<any> {

    return new Promise(resolve => {
      const subscription = this.httpClient.get<any>(url)
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);

          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  postAsync(url: string, objeto: any): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.post(url, objeto, {headers: this.header})
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { EXITO: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }

  putAsync(url: string, objeto: any): Promise<any> {
    return new Promise(resolve => {
      const subscription = this.httpClient.put(url, objeto, {headers: this.header})
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { EXITO: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }

  deleteAsync(url: string): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.delete<any>(url, {headers: this.header})
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { EXITO: true } as RespuestaModel);

          }, error => {

            subscription.unsubscribe();

            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }


  get() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/plantilla.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  obtenerUsuariosCSV_() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/usuarios.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  postAsyncURL(url: string, objeto: any): Promise<any> {
    return new Promise(resolve => {
      const subscription = this.httpClient.post(url, objeto, {headers: this.header})
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);

          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  getPdfAsync(url: string): Promise<any> {

    return new Promise(resolve => {
      const subscription = this.httpClient.get<any>(url,{headers: this.headerPdf})
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);

          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }



}

