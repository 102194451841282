
<div class="container">
<div class="card">
  <div class="d-flex justify-content-end">
   
  </div>
  <div class="card-body">
    <pdf-viewer [src]="pdfSrc"
    [render-text]="true"
    [show-all]="true"
    style="display: block;">
    </pdf-viewer>
  </div>
</div>
</div>
<div>
